<template>
  <div class="main">
    <div class="edit_password">
      <div class="form_box">
        <div class="title">修改密码</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="pwdRef"
          label-width="110px"
        >
          <div class="item">
            <el-form-item label="旧密码：" prop="old_pwd">
              <el-input
                type="password"
                v-model.trim="ruleForm.old_pwd"
              ></el-input>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="新密码：" prop="pwd">
              <el-input type="password" v-model.trim="ruleForm.pwd"></el-input>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="确认新密码：" prop="confirm_pwd">
              <el-input
                type="password"
                v-model.trim="ruleForm.confirm_pwd"
              ></el-input>
            </el-form-item>
          </div>
          <div class="msg">
            （密码长度6-16位，必须包含大写字母、小写字母和数字）
          </div>
          <div class="btn_box">
            <div class="btn" @click="confirmEdit">确 定</div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { UpdatePwd } from '@/api'
import MD5 from 'md5'

// 新密码需要验证
const checkPwd2 = (rule, value, cb) => {
  const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
  if (value.length < 6 || value.length > 16) {
    return cb(new Error('新密码长度错误'))
  }
  if (!reg.test(value)) {
    return cb(new Error('密码需包含‘大小写字母’和‘数字’'))
  }
  cb()
}
// 新密码需要验证
const checkPwd3 = (rule, value, cb) => {
  const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
  if (value.length < 6 || value.length > 16) {
    return cb(new Error('确认密码长度错误'))
  }
  if (!reg.test(value)) {
    return cb(new Error('密码需包含‘大小写字母’和‘数字’'))
  }
  cb()
}

export default {
  data() {
    return {
      ruleForm: {
        old_pwd: '',
        pwd: '',
        confirm_pwd: ''
      },
      rules: {
        old_pwd: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '旧密码长度为 6 - 16 位',
            trigger: 'blur'
          }
        ],
        pwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: checkPwd2, trigger: 'blur' }
        ],
        confirm_pwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: checkPwd3, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 确定修改密码
    confirmEdit() {
      this.$refs.pwdRef.validate(async (_) => {
        if (!_) return
        if (this.ruleForm.pwd !== this.ruleForm.confirm_pwd) {
          return this.$message.warning('两次输入的密码不一致')
        }
        const params = {
          old_pwd: MD5(this.ruleForm.old_pwd),
          pwd: MD5(this.ruleForm.pwd)
        }
        const { ret, data, msg } = await UpdatePwd(params)
        if (ret !== 0) {
          return this.$message.error(msg || '修改密码失败!')
        }
        this.$message.success('修改密码成功, 请重新登录!')
        setTimeout(() => {
          this.$store.dispatch('user/logout').then(() => {
            this.$router.push('/')
          })
        }, 1500)
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.edit_password {
  width: 100%;
  height: calc(100vh - 120px);
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;
}

.form_box {
  width: 440px;

  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #333;
  }

  .item {
    padding: 0 12px;
    margin: 24px 0;
  }

  .msg {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666;
  }

  .btn_box {
    margin-top: 36px;

    .btn {
      width: 180px;
      height: 36px;
      background: rgb(42, 71, 176);
      border-radius: 4px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #dfdfdf;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: rgba(42, 71, 176, 0.84);
      }
    }
  }
}
</style>
